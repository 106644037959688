<!-- 课程包内容列表 -->
<template>
  <div>
    <div class="main">
     <div style="font-size: 26px; font-weight: bold;display:flex"><div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;课程管理</div><div>/课程包列表/内容管理</div></div>
      <div>
        <!-- <div> -->
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='addcontent'
            >+添加内容</el-button
          >
          <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="2">视频</el-radio-button>
            <el-radio-button label="3">富文本</el-radio-button>
          </el-radio-group> -->
        <!-- </div> -->
        <div>
          <!-- <el-select
            v-model="value"
            placeholder="层级筛选"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入考试名称"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="date"  align="center" label="缩略图">
            <template slot-scope="scope">
                <div>
                    <!-- <video :src="scope.row.videoUrl" class="avatar"  style="width:170px;height:108px">您的浏览器不支持视频播放</video> -->
                    <img :src="scope.row.logoUri" alt="" style="width:170px;height:108px;border-radius:4px">
                </div>
            </template>
        </el-table-column>
         <el-table-column prop="weight" align="center" label="权重" width="200">
           <template slot-scope="scope">
                <div style="display:flex">
                    <el-input v-model="scope.row.weight" />
                    <el-link type="primary" :underline="false" @click='updateContentsWeight(scope.row.weight,scope.row.courseContentId)' style="flex-shrink:0">更新</el-link>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="名称">
        </el-table-column>

        <el-table-column prop="address"  align="center" width="180" label="操作">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
            <el-link type="primary" :underline="false" @click='lookvideo(scope.row)'>详情</el-link>
              <!-- <el-link type="primary" :underline="false" v-if="radio=='2'" @click='lookvideo(scope.row)'>查看视频</el-link>
              <el-link type="primary" :underline="false" v-if="radio=='3'" @click='lookcontent(scope.row)'>查看富文本</el-link> -->
              <el-link type="primary" :underline="false" @click='dele(scope.row)'>移除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content:space-between;
          margin-top:20px
        "
      >
        <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button>
        <el-button type="primary" @click='updateall' :disabled='isdisabled'>更新所有权重</el-button>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {contentpageList,contentsaveOrUpdate,deleteContents,pageListNoUse2,updateContentsWeightMethod} from '../../apis/index'
export default {
  data() {
    return {
      brandId:'',
      levelId:'',
        ids:[],
         id:'',//课程包id
      total:0,
      pageIndex:1,
      pageSize:10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "4",
      input: "",
      tableData: [
        
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input=''
        this.list(this.id)
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      this.radio=newdata
      this.pageIndex=1
      this.list(this.id)
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
  },
  created(){
    // console.log(this.$route.query.id)
     this.brandId=this.$route.query.brandId
    this.levelId=this.$route.query.levelId
    let id=this.$route.query.id
    this.id=id
    this.list(id)
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    updateContentsWeight(weight,id){
      const params=[{
        courseContentId:id,
        weight:Number(weight)
      }]
      if(!weight){
        this.$message({
                type: 'error',
                message: '请输入权重值'
              });
              return
      }
      this.$confirm('更新此内容的权重, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         updateContentsWeightMethod(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    //  const {data}=await updateContentsWeightMethod({
    //     courseContentId:id,
    //     weight:Number(weight)
    //   })
    //   console.log(data)
    },
       updateall() {
        this.$confirm('更新所有权重排序, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let tableData=this.tableData
          const params=tableData.map(item=>{
            return {
              courseContentId:item.courseContentId,
              weight:item.weight
            }
          })
          console.log(params)
           updateContentsWeightMethod(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.list(this.id)
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       goback(){
      this.$router.go(-1)
    },
    lookvideo(row){
      this.$router.push({path:'/videoAdd',query:row})
    },
    lookcontent(row){
      this.$router.push({path:'/contentAdd',query:row})
    },
   async list(id){//内容列表
      const params={
        // pageIndex:this.pageIndex,
        // pageSize:this.pageSize,
        // contentType:this.radio,
        // searchStr:this.input,
        courseId:id
      }
    //  const {data}=await contentpageList(params)
    const {data}=await pageListNoUse2(params)
     if(data.code==200){
       this.tableData=data.data,
       this.total=Number(data.data.length)
     }
     console.log(data)
    },
    addcontent(){//添加内容
        this.$router.push({path:'/content',query:{id:this.id,levelId:this.levelId,brandId:this.brandId}})
    //   if(this.radio=='3'){
    //     this.$router.push({path:'/contentAdd'})
    //   }else{
    //     this.$router.push({path:'/videoAdd'})
    //   }
      // this.$router.push({path:'/surveyPapge'})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
         let ids=val.map(item=>{
          return item.id
        })
        this.ids=ids
      },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.list(this.id)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.list(this.id)
    },
     dele(row) {
       console.log(row)
       const params={
         contentIds:[row.id],
         courseId:this.id
       }
        this.$confirm('此操作将从该课程包中移除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteContents(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list(this.id)
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
    
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
             const params={
                contentIds:this.ids,
                courseId:this.id
            }
           deleteContents(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list(this.id)
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
